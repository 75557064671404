import React, { createContext, useState } from "react"
import {useCycle} from 'framer-motion'
const defaultValues = {
  isMenuOpen: false,
  toggleMenuOpen: () => {
    console.log("open")
  },
}

export const MenuContext = createContext(defaultValues)

export const MenuProvider = ({ children }) => {
  // const [isMenuOpen, setMenuOpen] = useState(false)
  const [isMenuOpen, setMenuOpen] = useCycle(false, true);

  const toggleMenuOpen = () => setMenuOpen(!isMenuOpen)
  return (
    <MenuContext.Provider
      value={{ ...defaultValues, isMenuOpen, toggleMenuOpen }}
    >
      {children}
    </MenuContext.Provider>
  )
}
