import React from "react"
import { ApolloProvider } from "react-apollo"
import { client } from "./src/context/ApolloContext"
import { MenuProvider } from "./src/context/MenuContext"

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <MenuProvider>{element}</MenuProvider>
  </ApolloProvider>
)
